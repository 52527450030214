
import * as THREE from 'three'  //
import * as MathUtils from 'three/src/math/MathUtils'
import React, {Children,Suspense,useState,useRef,ReactNode, ReactElement } from 'react'
import {useThree} from '@react-three/fiber' // useThree, //useFrame,
import { useGLTF } from '@react-three/drei'


export default function LightEntity({ name, ...props }){

  const { nodes } = useGLTF(props.asset)
  const light =useRef();
  const scene = useThree((state) => state.scene) //is this used?

  const targetObject = new THREE.Object3D();
  targetObject.name=name+" target"
  targetObject.position.set(props.target!=undefined?props.target[0]:0,props.target!=undefined?props.target[1]:0,props.target!=undefined?props.target[2]:0);
  scene.add(targetObject);
  //NOTE:  UseFrame hook must me used to live track target object.
//  console.log("LightEntity:nodes",light,scene);


  return (
    <group{...props}  dispose={null}>
        <mesh geometry={nodes[name].geometry} material={nodes[name].material} />
        <spotLight castShadow intensity={props.intensity} ref={light} angle={Math.PI/props.angle} target={targetObject} position={[props.displacement[0], props.displacement[1],props.displacement[2]]} shadow-mapSize-width={2048} shadow-mapSize-height={2048} />
    </group>
  );

}
