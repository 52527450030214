import React, {Component,useRef, useState,useHotkeys,useEffect} from 'react'
import { useControls,folder } from 'leva'
import {Canvas, useFrame, scene,useThree} from '@react-three/fiber';
import {CameraShake,GizmoHelper,GizmoViewport,TransformControls,OrbitControls} from '@react-three/drei'
import * as THREE from 'three'
import {Perf} from "r3f-perf";
import Animator from '../src/components/pathgenerator/animator.js';
import Animation from '../src/components/pathgenerator/animation.js';
import Entity from '../src/components/entities/entity.js';
import LightEntity from '../src/components/entities/lightEntity.js';
import GroupEntity from '../src/components/entities/groupEntity.js'; //Incomplete!
import RenderTarget from   '../src/components/renderer/renderTarget.js';
import {v3Lerp} from'../src/cFunctions.js';
import tunnel from '../src/assets/models/Tunnel.gltf';
import ground from '../src/assets/models/Ground.gltf';
import lampPost from '../src/assets/models/LampPost.gltf';
import drone from '../src/assets/models/Drone.gltf';
import tree from '../src/assets/models/TreeGroup.gltf';
import bus from '../src/assets/models/Bus.gltf';

const material = new THREE.MeshPhysicalMaterial({
  color: new THREE.Color('#665C57').convertSRGBToLinear(),
  roughness: 1,
  metalness: 0.5,
  reflectivity: 0.2,
  fog: true,
  roughnessMap:null, //TODO: bricks roughness map
  clearcoat: 0,
  clearcoatRoughness: 0,
})

function CameraRig(props) {
  const rig =useRef();
  const [vec] = useState(() => new THREE.Vector3())

  const { camera} = useThree()
  const scene = useThree((state) => state.scene) //is this used?
  var object = scene.getObjectByName(props.followTarget);
  const defaultPos= camera.position;

  var targetPos= {x:0,y:0,z:0};
  rig.props={
    follow:props.follow,
    followTarget:props.followTarget,
    defaultPos:defaultPos,
    displacement:props.displacement,
  }
  const {gui}=useControls({
    DroneCam: {
      value:rig.props.follow,
      onChange: (v) => {
        rig.props.follow=v
        rig.current.setIntensity(rig.props.follow?1:0)
    //  object= scene.getObjectByName("drone")
        console.log("SceneA:Change",v,rig.current)

      }
    }
  })
  useFrame((delta)=>(
    targetPos=rig.props.follow==false?{x:rig.props.defaultPos.x,y:rig.props.defaultPos.y,z:rig.props.defaultPos.z}:{x:object.position.x+props.displacement[0],y:object.position.y+props.displacement[1],z:object.position.z+props.displacement[2]},
    object= scene.getObjectByName(props.followTarget),
    camera.position.lerp(vec.set(targetPos.x,targetPos.y,targetPos.z), 0.05)
  ));

  return <CameraShake ref={rig} maxYaw={0.01} maxPitch={0.01} maxRoll={0.01} yawFrequency={0.5} pitchFrequency={0.5} rollFrequency={0.4} />
}

function SimpleAnimation(props){
  const [vec] = useState(() => new THREE.Vector3())
  const scene = useThree((state) => state.scene) //is this used?
  var object = scene.getObjectByName(props.target);
var t=0;
var tpos=new THREE.Vector3();

  useFrame((delta)=>(
    t++,
    object=scene.getObjectByName(props.target),
     tpos=(v3Lerp(t,{x:props.startPos[0],y:props.startPos[1],z:props.startPos[2]},{x:props.targetPos[0],y:props.targetPos[1],z:props.targetPos[2]},props.speed,"loop")),
    object.position.set(tpos.x,tpos.y,tpos.z)
));


  return <> </>
}
export default function SceneA(props){ //Final App, used in navigation component
  //TODO: Debug Tool
/*
  const {gui}=useControls({
    debug: {
      value:false,
      onChange: (v) => {

        console.log("SceneA:Change",v)
      }
    }
  })*/

  //const [{ text }, set] = useControls(() => ({ text: 'my string' }))
  //return <input type="text" value={text} onChange={(e) => set({ text: e.target.value })} />
  return (
    <div className="PathGenerator App">
    <Canvas camera={{ position: [10, 10, 10], fov: 70 }}>
       <fog attach="fog" args={['navy', 50, 100]} />
    <Entity name="Tunnel" asset={tunnel} custMat={material} position={[0,3.9,-2]} scale={[1,-1,1]}/>
    <Entity name="Ground_1" asset={ground} rotation={[0,1.58,0]} position={[0,0,0]} scale={[1,1,1]}/>
    <Entity name="Ground_2" asset={ground} rotation={[0,1.58,0]} position={[0,0,0]} scale={[1,1,1]}/>
    <Entity name="Ground_3" asset={ground} rotation={[0,1.58,0]} position={[0,0,0]} scale={[1,1,1]}/>

    <GroupEntity name="Tree" names={["Trunk","Leaves02","Leaves03","Leaves04","Leaves01","Leaves"]}asset={tree} rotation={[0,1.58,0]} position={[-5,0.4,5]} scale={[1,1,1]} cPosition={[[0,0,0],[0,3,0],[0,4,0],[0,5,0],[0,6,0],[0,7,0]]}  cScale={[[1,1,1],[1.8,1,1.8],[1.5,1,1.5],[1.3,1,1.3],[0.9,1,0.9],[0.7,1,0.7]]}/>

    <GroupEntity name="Bus" names={["Bus_1","Bus_2","Bus_3","Bus_4","Bus_5","Bus_6","Bus_7","Bus_8","Wheels"]}asset={bus} rotation={[0,-1.58,0]} position={[0,0.4,2]} scale={[1,1,1]} cPosition={[[0,1,0],[0,1,0],[0,1,0],[0,1,0],[0,1,0],[0,1,0],[0,1,0],[0,1,0],[0,0,0]]}  cScale={[[1.25,1.25,1.25],[1.25,1.25,1.25],[1.25,1.25,1.25],[1.25,1.25,1.25],[1.25,1.25,1.25],[1.25,1.25,1.25],[1.25,1.25,1.25],[1.25,1.25,1.25],[1.1,1.1,1.1]]}/>
    <SimpleAnimation target="Bus" startPos={[0,0,-100]} targetPos={[0,0,100]} speed={0.1}/>

    <LightEntity name="LampPost_1" asset={lampPost} rotation={[0,1.58,0]} position={[2.2,0.5,5]} displacement={[0.5,3,0]} intensity={8} angle={6} target={[0.725,0,5]} scale={[1,1,1]}/>
    <Entity name="LampPost_2" asset={lampPost} rotation={[0,1.58,0]} position={[2.2,0.5,5]} scale={[1,1,1]}/>

    <LightEntity name="LampPost_1" asset={lampPost} rotation={[0,1.58,0]} position={[2.2,0.5,-5]} displacement={[0.5,3,0]} intensity={8} angle={6} target={[0.725,0,-5]} scale={[1,1,1]}/>
    <Entity name="LampPost_2" asset={lampPost} rotation={[0,1.58,0]} position={[2.2,0.5,-5]} scale={[1,1,1]}/>

  <RenderTarget position={[0,0,5]}/>
  //CONTUNUE ABOVE! Instead, set default camera as drone camera
    <GizmoHelper
     alignment="top-left"
     margin={[80, 80]}
    >
    <GizmoViewport axisColors={['red', 'green', 'blue']} labelColor="white" />
    </GizmoHelper>

    <CameraRig followTarget="drone" follow={false} displacement={[0,-1,0]}/>
    <OrbitControls makeDefault/>

        <Animator debug={true} startPos={{x:-3,y:2,z:7}}>
            <group key={0} target={true} name="drone">
              <Entity name="Cube003" asset={drone} rotation={[0,1.0,0]} position={[0,0,0]} scale={[1,1,1]}/>
              <Entity name="Cube003_1" asset={drone} rotation={[0,1.0,0]} position={[0,0,0]} scale={[1,1,1]}/>
              {/*console.log("HERE",gui)*/}
            </group>
            <Animation
              key={1}
              name="Start Sequence"
              targetPos={{x:0,y:5,z:-7}}
              curve={[0,0,0,0]}
              cpnt1={{x:0,y:20,z:-7}}
              cpnt2={{x:25,y:11,z:-15}}
              detail={30}
              duration={2.5} /**/
              playing={false}/>
              <Animation
                key={2}
                name="Second Sequence"
                targetPos={{x:2,y:5,z:-3}}
                curve={[0,0,0,0]}
                cpnt1={{x:-15,y:-3,z:-1}}
                cpnt2={{x:15,y:3,z:-15}}
                detail={30}
                duration={4}/**/
                playing={false}/>
                <Animation
                  key={3}
                  name="Third Sequence"
                  targetPos={{x:-3,y:2,z:7}}
                  curve={[0,0,0,0]}
                  cpnt1={{x:-20,y:0,z:-5}}
                  cpnt2={{x:-20,y:10,z:20}}
                  detail={30}
                  duration={3}/**/
                  playing={false}/>
         </Animator>

         <ambientLight intensity={0.25} />
         <directionalLight position={[0, 0, 5]} color={'rgb(155,155,255)'}/>


          <Perf position='bottom-left'/>
       </Canvas>
     </div>
  );
}

//   <Entity name="Leaves" asset={tree} rotation={[0,1.58,0]} position={[-5,5,5]} scale={[1,1,1]}/>
