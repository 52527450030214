import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function GroupEntity({ names, ...props }) {
  //const { nodes } = useGLTF(props.asset)
const { nodes } = useGLTF(props.asset)
//../../cFunction
  console.log("GroupEntity:nodes",names,nodes);
  return (
    <group {...props} dispose={null}>

    {names.map((n,i) => {
    // console.log(i,n,"GroupEntity_n",nodes[n]);

      return(
        <mesh geometry={nodes[n].geometry} key={i} material={nodes[n].material} position={props.cPosition[i]} scale={props.cScale[i]}/>
      )
       })
    }
    </group>
  )
}

//useGLTF.preload('/materials/suzanne.gltf')
