import * as THREE from 'three'
import * as MathUtils from 'three/src/math/MathUtils'
import React, {useEffect,useState,useRef } from 'react'
import { Canvas, useThree,useFrame} from '@react-three/fiber'



export default function RenderTarget(props){
  const mesh =useRef();
  const scene = useThree((state) => state.scene) //is this used?

   const renderer = new THREE.WebGLRenderer({antialias: true});

//  const renderScene = new THREE.Scene();
//renderScene.background = new THREE.Color(0x404040);

const renderCamera = new THREE.PerspectiveCamera(60, 1.0, 0.01, 100.0);
renderCamera.position.set(0.0, 0.0, 3.0);
renderCamera.lookAt(new THREE.Vector3());

const renderTarget = new THREE.WebGLRenderTarget(512, 512);
//renderer.setSize(500,500);
// renderer.render(scene, renderCamera,renderTarget);
const renderMaterial=new THREE.MeshBasicMaterial({
       map: renderer.texture
     })

 //plane.position.set(props.position[0],props.position[1],props.position[2])
//scene.add(plane);

      useFrame((state,delta)=>(
      // plane.material.map=renderer.texture,
      //  console.log("RenderTarget",plane.material),
        {}
  ));

  return ( //position={[props.targetPos.x,props.targetPos.y,props.targetPos.z]}
    <group ref={mesh} >
      <planeGeometry attach="geometry"/>
      <meshStandardMaterial  attach="material" color="orange" />
    </group>
  );
}
