import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Entity({ name, ...props }) {
  //const { nodes } = useGLTF(props.asset)
const { nodes } = useGLTF(props.asset)
const hasChildren=nodes[name].children.length>0?true:false;

//../../cFunction
  console.log(name," Entity:nodes",nodes," children: ",hasChildren,"custMat",props.custMat);

  return (
    <group {...props} dispose={null}>
      {hasChildren &&
        nodes[name].children.map((child,i) => {
          if(child.children.length==0){
              <mesh geometry={child.geometry} material={props.custMat!=undefined?props.custMat:child.material} />
          }
         })
      }
      {!hasChildren &&
        <mesh geometry={nodes[name].geometry}material={props.custMat!=undefined?props.custMat:nodes[name].material} />
      }

    </group>
  )
}

//useGLTF.preload('/materials/suzanne.gltf')
