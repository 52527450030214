
import './Devinfo.css';
import square_mem from './square_mem.jpg';
export default function Devinfo(){

  return (
      <div className="Devinfo">
      <div>
        <p>Hi there, I'm Mem,a programmer of websites, games and experimental art.</p>
        <p>What you're seeing right now is a digital space I've created for the sole purpose of housing my experiments with react and react three fibre.</p>
        <p> If you'd like to know more about me or to see my published work please visit <a href="https://merk.click/">my portfolio</a>.</p>
        </div>
      <div></div>
      </div>
  );
}
